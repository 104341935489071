@font-face {
  font-family: "Arial";
  src: local("Arial"), url(./assets/fonts/Arial.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: "Arial", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Arial", Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.custom-link {
  text-decoration: none;
  font-weight: 600;
  color: #162a6b;
}



